import React, { useState } from 'react';
import axios from 'axios';
import '../styles/main.scss';

const ContactForm: React.FC = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [status, setStatus] = useState<string | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setStatus(null);

    try {
      await axios.post('http://localhost:3000/contact', formData);
      setStatus('Message envoyé ✅');
      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      setStatus("Erreur lors de l'envoi ❌");
    }
  };

  return (
    <section className="contact">
      <h2 className="contact__title">📬 Me Contacter</h2>
      <form className="contact__form" onSubmit={handleSubmit}>
        <input
          className="contact__field"
          type="text"
          name="name"
          placeholder="Votre nom"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <input
          className="contact__field"
          type="email"
          name="email"
          placeholder="Votre email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <textarea
          className="contact__field"
          name="message"
          placeholder="Votre message"
          value={formData.message}
          onChange={handleChange}
          required
        />
        <button className="contact__button" type="submit">
          Envoyer
        </button>
        {status && <p className="contact__status">{status}</p>}
      </form>
    </section>
  );
};

export default ContactForm;
