import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/main.scss';
import { useAuth } from '../context/AuthContext';

interface BlogPost {
  id: number;
  title: string;
  content: string;
  date: string;
}

const Blog: React.FC = () => {
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [message, setMessage] = useState('');
  const [editingId, setEditingId] = useState<number | null>(null);
  const [editTitle, setEditTitle] = useState('');
  const [editContent, setEditContent] = useState('');
  const { isAdmin, adminKey } = useAuth();

  const API_URL = 'http://localhost:3000/blog';

  const fetchPosts = async () => {
    const res = await axios.get(API_URL);
    setPosts(res.data);
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await axios.post(API_URL, { title, content, adminKey });
      setTitle('');
      setContent('');
      setMessage('✅ Article publié !');
      fetchPosts();
    } catch {
      setMessage("❌ Erreur de publication (clé ?)");
    }
  };

  const handleDelete = async (id: number) => {
    try {
      await axios.delete(`${API_URL}/${id}`, { data: { adminKey } });
      setMessage('🗑️ Article supprimé.');
      fetchPosts();
    } catch {
      setMessage("❌ Erreur de suppression (clé ?)");
    }
  };

  const handleEdit = (post: BlogPost) => {
    setEditingId(post.id);
    setEditTitle(post.title);
    setEditContent(post.content);
  };

  const handleUpdate = async () => {
    if (!editingId) return;
    try {
      await axios.put(`${API_URL}/${editingId}`, {
        title: editTitle,
        content: editContent,
        adminKey
      });
      setEditingId(null);
      setMessage('✏️ Article mis à jour');
      fetchPosts();
    } catch {
      setMessage("❌ Erreur de mise à jour");
    }
  };

  return (
    <section className="blog">
      <h2 className="blog__title">📰 Blog</h2>

      {isAdmin && (
        <form className="blog__form" onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Titre"
            className="blog__field"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <textarea
            placeholder="Contenu"
            className="blog__field"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            required
          />
          <button className="blog__button" type="submit">Publier</button>
        </form>
      )}

      {message && <p className="blog__status">{message}</p>}

      <div className="blog__list">
        {posts.map((post) => (
          <div key={post.id} className="blog__item">
            {editingId === post.id ? (
              <>
                <input
                  className="blog__field"
                  value={editTitle}
                  onChange={(e) => setEditTitle(e.target.value)}
                />
                <textarea
                  className="blog__field"
                  value={editContent}
                  onChange={(e) => setEditContent(e.target.value)}
                />
                <button className="blog__button" onClick={handleUpdate}>Enregistrer</button>
                <button className="blog__button" onClick={() => setEditingId(null)}>Annuler</button>
              </>
            ) : (
              <>
                <h3 className="blog__item-title">{post.title}</h3>
                <p className="blog__item-content">{post.content}</p>
                <small className="blog__item-date">🕒 {new Date(post.date).toLocaleString()}</small>
                {isAdmin && (
                  <div className="blog__actions">
                    <button onClick={() => handleEdit(post)}>✏️</button>
                    <button onClick={() => handleDelete(post.id)}>🗑️</button>
                  </div>
                )}
              </>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default Blog;
