import React from 'react';
import '../styles/main.scss';

const About: React.FC = () => {
  return (
    <section className="about">
      <div className="container">
        <h1 className="about__title">À propos de moi</h1>
        <p className="about__intro">
          Bienvenue ! Je suis Ibrahim, un développeur web passionné, actuellement en 5e année à l'ESGI Paris.
        </p>

        <div className="about__section">
          <h2 className="about__heading">Expériences professionnelles</h2>
          <ul className="about__list">
            <li className="about__item">
              <h3>Développeur Logiciel chez Pyromeral Systems</h3>
              <span>Septembre 2022 - Présent</span>
              <p>Développement complet d’un logiciel interne pour la génération de documents et automatisation des processus.</p>
            </li>
            <li className="about__item">
              <h3>Dépannage Informatique</h3>
              <span>2016 - 2020</span>
              <p>Montage, configuration, reconditionnement d’ordinateurs en entreprise et chez les particuliers.</p>
            </li>
            <li className="about__item">
              <h3>Auto-entrepreneur — Livraison</h3>
              <span>Mars 2021 - Présent</span>
              <p>Livraison à vélo via UberEats et Deliveroo dans la zone de Compiègne.</p>
            </li>
          </ul>
        </div>

        <div className="about__section">
          <h2 className="about__heading">Formation</h2>
          <ul className="about__list">
            <li className="about__item">
              <h3>Mastère Ingénierie du Web - ESGI Paris</h3>
              <span>2023 - 2025</span>
              <p>Spécialisation en frameworks modernes et développement fullstack.</p>
            </li>
            <li className="about__item">
              <h3>Bachelor Ingénierie du Web - ESGI Paris</h3>
              <span>2022 - 2023</span>
              <p>Apprentissage des fondamentaux du web et des technologies back/front modernes.</p>
            </li>
            <li className="about__item">
              <h3>BTS SNIR - Condorcet Saint-Quentin</h3>
              <span>2019 - 2021</span>
              <p>Développement C/C++, PHP, HTML/CSS, avec gestion de projets et réseaux.</p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default About;
