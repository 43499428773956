import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/main.scss';

const Home: React.FC = () => {
  return (
    <section className="home">
      {/* HERO SECTION */}
      <div className="home__hero">
        <img
          src="/assets/Profile2.JPEG"
          alt="Portrait d'Ibrahim"
          className="home__photo"
        />
        <div className="home__intro">
          <h1>Bonjour, je suis <span>Ibrahim</span></h1>
          <h2>Développeur Fullstack</h2>
          <Link to="/projects" className="home__cta">Découvrir mes projets</Link>
        </div>
      </div>

      {/* A PROPOS */}
      <div className="home__about">
        <h2>À propos</h2>
        <div className="home__about-content">
          <p>
            Je suis un développeur passionné par la création d’interfaces modernes,
            l’expérience utilisateur et le développement fullstack. Actuellement en Mastère à l’ESGI Paris.
          </p>
          <img
            src="/assets/Dev.png"
            alt="illustration développeur"
            className="home__about-image"
          />
        </div>
      </div>

      {/* Projets récents */}
      <div className="home__projects-preview">
        <h2>Projets</h2>
        <div className="home__projects-list">
          <div className="project-card">
            <h3>Portfolio</h3>
            <p>Site personnel avec React, SCSS, Node.js et Docker.</p>
            <Link to="/projects">Voir plus</Link>
          </div>
          <div className="project-card">
            <h3>Blog Personnel</h3>
            <p>Blog connecté avec back Node/Express + front React.</p>
            <Link to="/projects">Voir plus</Link>
          </div>
          <div className="project-card">
            <h3>Projet E-learning</h3>
            <p>Application de gestion de cours pour écoles GES.</p>
            <Link to="/projects">Voir plus</Link>
          </div>
        </div>
      </div>

      {/* Les vraies compétences sont ailleurs, ici on peut faire un lien vers la page */}
      <div className="home__skills-link">
        <h2>Compétences</h2>
        <p>Retrouvez toutes mes stacks frontend, backend, outils et bases de données.</p>
        <Link to="/Skills" className="home__cta">Voir mes compétences</Link>
      </div>
    </section>
  );
};

export default Home;
