// src/pages/Service.tsx
import React from 'react';
import '../styles/main.scss';

const Service: React.FC = () => {
  return (
    <section className="services">
      <div className="container">
        <h1 className="services__title">Mes Services</h1>
        <div className="services__list">
          <div className="services__item">
            <h3>Développement Web</h3>
            <p>
              Création de sites web responsives, performants et modernes avec React, NestJS, et SCSS.
            </p>
          </div>
          <div className="services__item">
            <h3>Déploiement & CI/CD</h3>
            <p>
              Configuration de pipelines de déploiement, intégration continue, Docker, et gestion de VPS.
            </p>
          </div>
          <div className="services__item">
            <h3>Conception d'API</h3>
            <p>
              Architecture et développement d’API REST avec NestJS, sécurisation et documentation via Swagger.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Service;