import React, { useEffect } from 'react';
import '../styles/main.scss';

interface Skill {
  name: string;
  logo: string;
}

interface SkillCategory {
  title: string;
  skills: Skill[];
}

const skillCategories: SkillCategory[] = [
  {
    title: 'Frontend',
    skills: [
      { name: 'HTML', logo: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg' },
      { name: 'CSS/SCSS', logo: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg' },
      { name: 'JavaScript', logo: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg' },
      { name: 'TypeScript', logo: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/typescript/typescript-original.svg' },
      { name: 'Vue.js', logo: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vuejs/vuejs-original.svg' },
      { name: 'React', logo: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg' },
      { name: 'Twig', logo: 'https://www.svgrepo.com/show/354431/twig.svg' },
      { name: 'TailwindCSS', logo: 'https://www.svgrepo.com/show/374118/tailwind.svg' },
    ],
  },
  {
    title: 'Backend',
    skills: [
      { name: 'Node.js', logo: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg' },
      { name: 'NestJS', logo: 'https://www.svgrepo.com/show/354380/nestjs-icon.svg' },
      { name: 'Symfony', logo: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/symfony/symfony-original.svg' },
    ],
  },
  {
    title: 'Bases de Données',
    skills: [
      { name: 'PostgreSQL', logo: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/postgresql/postgresql-original.svg' },
      { name: 'MongoDB', logo: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-original.svg' },
      { name: 'MySQL', logo: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original.svg' },
      { name: 'Airtable', logo: 'https://www.svgrepo.com/show/452216/airtable.svg' },
      { name: 'HFSQL', logo: 'https://upload.wikimedia.org/wikipedia/fr/thumb/0/05/HFSQL.svg/1280px-HFSQL.svg.png' },
    ],
  },
  {
    title: 'Outils',
    skills: [
      { name: 'Git', logo: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-original.svg' },
      { name: 'Docker', logo: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/docker/docker-original.svg' },
      { name: 'CI/CD', logo: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/github/github-original.svg' },
      { name: 'Figma', logo: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/figma/figma-original.svg' },
      { name: 'Burp Suite', logo: 'https://www.svgrepo.com/show/303481/burp-suite.svg' },
      { name: 'Clean Code', logo: 'https://www.svgrepo.com/show/373700/book.svg' },
      { name: 'WinDev', logo: 'https://upload.wikimedia.org/wikipedia/commons/c/cc/Logo_WinDev.png' },
    ],
  },
];

const Skills: React.FC = () => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        entry.target.classList.toggle('show', entry.isIntersecting);
      });
    });

    const targets = document.querySelectorAll('.skill-item');
    targets.forEach((el) => {
      el.classList.add('hidden');
      observer.observe(el);
    });
  }, []);

  return (
    <section className="skills container">
      <h2>💼 Mes Compétences</h2>
      {skillCategories.map((cat) => (
        <div key={cat.title} className="skills__category">
          <h3>{cat.title}</h3>
          <div className="skills__grid">
            {cat.skills.map((skill) => (
              <div key={skill.name} className="skill-item">
                <img src={skill.logo} alt={skill.name} />
                <span>{skill.name}</span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </section>
  );
};

export default Skills;
