import React from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/main.scss';

const Sidebar: React.FC = () => {
  return (
    <aside className="sidebar">
      <div className="sidebar__logo">IbrahimOuahabi.fr</div>
      <nav className="sidebar__nav">
        <NavLink to="/" className={({ isActive }) => isActive ? 'active' : ''}>Accueil</NavLink>
        <NavLink to="/about" className={({ isActive }) => isActive ? 'active' : ''}>À propos</NavLink>
        <NavLink to="/projects" className={({ isActive }) => isActive ? 'active' : ''}>Projets</NavLink>
        <NavLink to="/services" className={({ isActive }) => isActive ? 'active' : ''}>Services</NavLink>
        <NavLink to="/skills" className={({ isActive }) => isActive ? 'active' : ''}>Compétences</NavLink>
        <NavLink to="/contact" className={({ isActive }) => isActive ? 'active' : ''}>Contact</NavLink>
      </nav>
      <div className="sidebar__footer">
        <a href="https://github.com/Narutino10" target="_blank" rel="noopener noreferrer">GitHub</a>
        <a href="https://linkedin.com/in/ibrahim-ouahabi" target="_blank" rel="noopener noreferrer">LinkedIn</a>
        <a href="mailto:ibrahim.ouahabi@outlook.fr">Contact</a>
        <a href="https://www.instagram.com/ibrahim.ohb">Instagram</a>
      </div>
    </aside>
  );
};

export default Sidebar;
