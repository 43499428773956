import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import '../styles/main.scss';

const Login: React.FC = () => {
  const [key, setKey] = useState('');
  const { login } = useAuth();

  const handleLogin = () => {
    login(key);
  };

  return (
    <div className="login">
      <div className="login__container">
        <h2 className="login__title">🔐 Connexion Admin</h2>
        <input
          type="password"
          className="login__input"
          placeholder="Clé admin"
          value={key}
          onChange={(e) => setKey(e.target.value)}
        />
        <button className="login__button" onClick={handleLogin}>
          Se connecter
        </button>
      </div>
    </div>
  );
};

export default Login;
