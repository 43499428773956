// src/pages/FAQ.tsx
import React, { useState } from 'react';
import '../styles/main.scss';

const faqData = [
  {
    question: 'Comment puis-je vous contacter ?',
    answer: 'Vous pouvez utiliser le formulaire de contact disponible dans la section "Contact" de ce site.',
  },
  {
    question: 'Quelles technologies maîtrisez-vous ?',
    answer: 'Je travaille avec React, NestJS, SCSS, PostgreSQL, GitHub, Docker, WinDev, et plus encore.',
  },
  {
    question: 'Êtes-vous disponible pour des projets freelance ?',
    answer: 'Oui, je suis ouvert aux opportunités freelance selon mes disponibilités.',
  },
];

const FAQ: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleIndex = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="faq">
      <div className="container">
        <h1 className="faq__title">Foire Aux Questions</h1>
        <div className="faq__list">
          {faqData.map((item, index) => (
            <div key={index} className="faq__item">
              <button className="faq__question" onClick={() => toggleIndex(index)}>
                {item.question}
              </button>
              {activeIndex === index && <p className="faq__answer">{item.answer}</p>}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
