import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// import Footer from './components/Footer';
import ContactForm from './pages/ContactForm';
import Blog from './pages/Blog';
import Login from './pages/Login';
import About from './pages/About';
import Projects from './pages/Projects';
import FAQ from './pages/FAQ';
import Services from './pages/Service';
import Home from './pages/home';
import Skills from './pages/Skills';
import Layout from './layouts/Layout'; // chemin corrigé

import { AuthProvider } from './context/AuthContext';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <div className="app">
          <Routes>
            <Route path="/" element={<Layout><Home /></Layout>} />
            <Route path="/about" element={<Layout><About /></Layout>} />
            <Route path="/projects" element={<Layout><Projects /></Layout>} />
            <Route path="/faq" element={<Layout><FAQ /></Layout>} />
            <Route path="/services" element={<Layout><Services /></Layout>} />
            <Route path="/login" element={<Layout><Login /></Layout>} />
            <Route path="/skills" element={<Layout><Skills /></Layout>} />
            <Route path="/blog" element={<Layout><Blog /></Layout>} />
            <Route path="/contact" element={<Layout><ContactForm /></Layout>} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;
