// src/pages/Projects.tsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/main.scss';

interface Repo {
  id: number;
  name: string;
  html_url: string;
  description: string;
  language: string;
}

const Projects: React.FC = () => {
  const [repos, setRepos] = useState<Repo[]>([]);

  useEffect(() => {
    axios
      .get<Repo[]>('https://api.github.com/users/Narutino10/repos')
      .then((res) => setRepos(res.data))
      .catch((err) => console.error('Erreur chargement GitHub:', err));
  }, []);

  return (
    <section className="projects">
      <div className="container">
        <h1 className="projects__title">Mes Projets GitHub</h1>
        <div className="projects__list">
          {repos.map((repo) => (
            <div className="projects__item" key={repo.id}>
              <h3>{repo.name}</h3>
              <p>{repo.description || 'Aucune description.'}</p>
              <p><strong>Langage :</strong> {repo.language || 'Non spécifié'}</p>
              <a href={repo.html_url} target="_blank" rel="noopener noreferrer">
                🔗 Voir sur GitHub
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Projects;
